import React from "react"
import Img from "gatsby-image"
import CallButton from "../components/callButton"

function HeroSection(props) {
  return (
    <section>
      <div className="py-16 px-2 md:px-0">
        <h1 className="text-4xl text-center font-semibold text-blue-400 pb-16">
          {props.h1}
        </h1>
        <div className="flex flex-col-reverse lg:flex-row">
          <div className="md:w-full lg:mr-8">
            <div className="md:flex">
              <h2 className="font-bold leading-8 text-2xl mb-4">{props.h2}</h2>
            </div>
            <div className="md:flex">
              <p className="text-gray-600 leading-normal text-lg mb-4">
                {props.h3}
              </p>
            </div>
            <div className="md:flex">
              <p className="text-gray-600 text-lg mb-4">{props.p1}</p>
            </div>
            <div className="text-center md:flex md:justify-center pt-8 lg:pt-0 lg:justify-start">
              <div className="mb-4 md:mb-0 md:mr-8">
                <CallButton />
              </div>
            </div>
          </div>
          <div className="md:w-full mb-8 lg:mb-0">
            <div className="flex items-center">
              <Img
                className="w-full rounded shadow-lg"
                fluid={props.heroImg}
                alt={props.heroImgAltTag || `North Houston Plumbing Services`}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HeroSection
