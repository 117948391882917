import React from "react"

const SubServices = props => {
  return (
    <section id="education">
      <div className="fullWidth py-16 px-2 md:px-0">
        <h2 className="text-3xl text-center text-blue-400 font-semibold pb-16">
          {props.mainTitle}
        </h2>
        <div className="container">
          <div className="md:w-full md:mb-8 lg:mr-8">
            <div className="md:flex">
              <div className="md:w-1/2 lg:max-w-1/4 rounded bg-white overflow-hidden shadow-lg mb-8 md:mr-8 md:mb-0">
                <div className="px-3 py-4 md:px-6 md:py-8">
                  <h3 className="font-bold text-xl mb-4">{props.titleOne}</h3>
                  <p className="text-gray-600 text-base">
                    {props.descriptionOne}
                  </p>
                </div>
              </div>
              <div className="md:w-1/2 lg:max-w-1/4 rounded bg-white overflow-hidden shadow-lg mb-8 md:mr-8 md:mb-0">
                <div className="px-3 py-4 md:px-6 md:py-8">
                  <h3 className="font-bold text-xl mb-4">{props.titleTwo}</h3>
                  <p className="text-gray-600 text-base">
                    {props.descriptionTwo}
                  </p>
                </div>
              </div>
              <div className="md:w-1/2 lg:max-w-1/4 bg-white rounded overflow-hidden shadow-lg mb-8 md:mb-0">
                <div className="px-3 py-4 md:px-6 md:py-8">
                  <h3 className="font-bold text-xl mb-4">{props.titleThree}</h3>
                  <p className="text-gray-600 text-base">
                    {props.descriptionThree}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SubServices
