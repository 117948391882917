import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import CTA from "../components/cta"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ServiceAreas from "../components/serviceAreas"
import HeroSection from "../components/heroSection"
import SubServicesAlt from "../components/subServicesAlt"
import Education from "../components/education"
import Testimonials from "../components/testimonials"

const WaterheaterServices = () => {
  const data = useStaticQuery(graphql`
    query waterheaterImages {
      waterHeaters: file(relativePath: { eq: "water-heater-repair.jpg" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO
        title="Water Heater Repair"
        description={`Our certified and experienced team is able to repair your home's water heater. We're fast, professional, and affordable. Get your 100% free quote today.`}
      />
      <HeroSection
        h1={`Water Heater Services`}
        h2={`Have an Experienced Master Plumber Fix Your Water Heater`}
        h3={`Hot water is an essential part of your home for cooking and cleaning. If your water heater is malfunctioning, Klein Plumbing Services can repair it so you can have peace of mind.`}
        p1={`We have vast experience with water heaters over three decades of serving the North Houston area, which is why our service is safe, affordable, and thorough.`}
        heroImg={data.waterHeaters.childImageSharp.fluid}
        heroImgAltTag={`Water Heater Repair Services`}
        buttonTitle={`Water Heater Services`}
        path={`/water-heaters#services`}
      />
      <Education
        mainTitle={`Signs Your Water Heater Needs Repairing`}
        titleOne={`It's Not Getting Hot`}
        descriptionOne={`A worn-out heater can cause your water to not get as hot as it used to. If you're living with cold showers, it's time to contact us!`}
        titleTwo={`It's Leaking`}
        descriptionTwo={`A leak or crack in your tank can cause water to pool at the base. This can cause severe damage to the heater or your home. `}
        titleThree={`It's Rusted`}
        descriptionThree={`When you see rust around your water heater, it's definitely time to contact a plumber. Rust can eventually lead to corrosion and tank failure.`}
      />
      <SubServicesAlt
        titleOne={`General Service`}
        descriptionOne={`If your water isn't getting hot anymore, our master plumber can diagnose and resolve the issue quickly.`}
        titleTwo={`Leak Repair`}
        descriptionTwo={`We can repair cracks and leaks in your water heater so it can run more efficiently.`}
        titleThree={`Valves and Supply`}
        descriptionThree={`Your water heater valves and supply lines need occasional repairs. Preventative maintenance goes a long way to preserving the life of your unit.`}
      />
      {/* <Testimonials /> */}
      <ServiceAreas
        description={`water heaters`}
        subDescription={`water heater repairs`}
      />
      <CTA title={`Get a Free Quote to Fix Your Water Heater Now`} />
    </Layout>
  )
}

export default WaterheaterServices
